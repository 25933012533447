import { useState, useEffect } from "react";
import api from "../src/api";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import _ from "lodash";
var fileDownload = require("js-file-download");
import Swal from "sweetalert2";
import { getSession } from "next-auth/client";
import nookies from "nookies";
import { parseCookies } from "nookies";
import Exam from "./../public/img/exam.svg";
import * as yup from "yup";
import { getRealTime } from "@src/api/cdn";

import * as typeformEmbed from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";

import { createDiplomaEvent6 } from "@src/instatag";

import { blackListedEmails } from "./dashboard-fns";

export const getComponent = (children, key) => {
  return children.filter((component) => {
    return component ? component.key === key : null;
  });
};

export const getName = async (user_id = null) => {
  let session = null;

  if (!user_id) session = await getSession();

  const { name, last_name, second_last_name } = await new api.GetUser(
    user_id ? user_id : session.user.image.user_id
  ).doQuery();

  let secondLastName = "";
  switch (second_last_name) {
    case "null":
    case "NULL":
    case "no-aplica":
    case "no aplica":
      secondLastName = "";
      break;

    default:
      secondLastName = second_last_name;
      break;
  }

  return _.toUpper(`${name} ${last_name} ${secondLastName}`);
};

export const diplomaWithInvoicePDF = async (event, user_id) => {
  // return { value : "1234" }

  let userInvoice = await new api.GetUserInvoicePDF(
    event.id,
    user_id
  ).doQuery();
  // console.log('userInvoice ->', userInvoice)

  if (!userInvoice?.id) {
    let method =
      event.status === "live" ? api.GetNewLiveInvoice : api.GetNewVodInvoice;

    let newInvoice = await new method(event.id).doQuery();

    if (!newInvoice) return { no_invoices: true };

    let markInvoice = await new api.MarkInvoicePDF(
      newInvoice.id,
      user_id,
      event.id
    ).doMutation();

    return newInvoice;
  }

  return userInvoice;
};

const diplomaWithInvoice = async (event, session) => {
  let userInvoice = await new api.GetUserInvoice(event.id).doQuery(
    null,
    session.user.image.access_token
  );
  console.log("userInvoice ->", userInvoice);

  if (!userInvoice?.id) {
    //let method = event.status === 'vod' ? api.GetNewVodInvoice : api.GetNewLiveInvoice
    let method =
      event.status === "live" ? api.GetNewLiveInvoice : api.GetNewVodInvoice;

    let newInvoice = await new method(event.id).doQuery(
      null,
      session.user.image.access_token
    );
    console.log("newInvoice->", newInvoice);

    if (!newInvoice) {
      makeDiploma(event, session);
      return { no_invoices: true };
    }

    let markInvoice = await new api.MarkInvoice(
      newInvoice.id,
      event.id
    ).doMutation(null, session.user.image.access_token);
    console.log("markInvoice ->", markInvoice);

    return newInvoice;
  }

  return userInvoice;
};

export const makeDiplomaSeriesDecorator = (fn) => {
  return async function (...args) {
    console.log(
      "🛎 in make diploma decorator:",
      args[0],
      args[1],
      false,
      true,
      args[0].diploma_serie.id,
      args[0].diploma_serie.min_play
    );

    let progress = await new api.GetAllSerieProgress(args[0].id).doQuery(
      null,
      args[1]
    );

    let series = {
      diploma_id: args[0].diploma_serie.diploma.id,
      percentage: progress,
    };

    const hasAnswer = await hasAnsweredSurvey(args[0], args[1]);

    if (!hasAnswer) return;

    if (progress >= args[0].diploma_serie.min_play)
      fn(args[0], args[1], false, true, series);

    if (progress < args[0].diploma_serie.min_play) {
      Swal.fire({
        title: "Tiempo acumulado insuficiente",
        text: `Para poder obtener su constancia es necesario ver el ${
          args[0].new_diploma_rule ? "100" : "80"
        }% del programa. Agradecemos su comprensión.`,
        confirmButtonColor: "#077545",
        confirmButtonText: "Entendido",
      });
    }
  };
};

export const makeAlatDecorator = (makeDiploma) => {
  return async function (...args) {
    console.log("🛎 in make diploma decorator ALAT:", args);

    const hasAnswer = await hasAnsweredSurvey(args[0], args[1]);

    if (!hasAnswer) return;

    const eventMinPlay = await new api.GetEventMinPlay(args[0].id).doQuery(
      null
    );

    const minPLay = +eventMinPlay.replace("%", "");

    const progress = await new api.GetLiveAlatProgress().doQuery(null, args[1]);

    if (Math.ceil(progress) >= minPLay) {
      makeDiploma(args[0], args[1], false, true);
    } else {
      Swal.fire({
        title: "Tiempo acumulado insuficiente",
        text: "Para poder obtener su constancia es necesario visualizar las 4 sesiones de este programa. Agradecemos su comprensión.",
        confirmButtonColor: "#077545",
        confirmButtonText: "Entendido",
      });
    }
  };
};

export const makeDiplomaDecorator = (fn) => {
  return async function (...args) {
    console.log("in make diploma decorator -> ", args);

    let hasExam = args[4];

    if (!hasExam) fn(args[0], args[1], args[2], false, false, args[5]);

    if (hasExam) {
      const hasAprovedExam = await new api.HasAprovedExam(args[0].id).doQuery(
        null,
        args[1].user.image.access_token
      );
      console.log("has aprovedExam -> ", hasAprovedExam);

      if (hasAprovedExam) fn(args[0], args[1], args[2], false, false, args[5]);

      if (!hasAprovedExam) {
        Swal.fire({
          title: "Es necesario aprobar el examen",
          icon: "error",
          confirmButtonText: "Entendido",
        });
        return;
      }
    }
    //Look if has answeres and approved the exam (if not show another alert)
  };
};

const hasAnsweredSurvey = async (event, session) => {
  if (!event?.typeform_id) {
    return true;
  }

  let hasAnswered = await new api.HasAnsweredSurvey(event.id).doQuery(
    null,
    session.user.image.access_token
  );
  hasAnswered = hasAnswered?.has_answered;

  if (!hasAnswered) {
    Swal.fire({
      title: "Encuesta de satisfacción no respondida",
      text: "Para poder obtener su constancia es necesario responder nuestra encuesta de satisfacción. Agradecemos su comprensión.",
      confirmButtonColor: "#077545",
      confirmButtonText: "Entendido",
    });

    return false;
  }

  return true;
};

const validateDiploma = (event, session) => {
  return new Promise(async (resolve) => {
    let isDiploma = await new api.IsDiplomaAvailable(event.id).doQuery(
      null,
      session.user.image.access_token
    );
    let hasAnswered = await new api.HasAnsweredSurvey(event.id).doQuery(
      null,
      session.user.image.access_token
    );

    if (!hasAnswered?.has_answered) {
      Swal.fire({
        title: "Encuesta de satisfacción no respondida",
        text: "Para poder obtener su constancia es necesario responder nuestra encuesta de satisfacción. Agradecemos su comprensión.",
        confirmButtonColor: "#077545",
        confirmButtonText: "Entendido",
      });
      resolve(false);
    }

    if (!isDiploma?.is_diploma_released) {
      const eventMinPlay = await new api.GetEventMinPlay(event.id).doQuery(
        null
      );

      Swal.fire({
        title: "Tiempo acumulado insuficiente",
        text: `Para poder obtener su constancia es necesario ver el ${eventMinPlay} del evento. Agradecemos su comprensión.`,
        confirmButtonColor: "#077545",
        confirmButtonText: "Entendido",
      });
      resolve(false);
    }

    resolve(true);
  });
};

export const makeDiploma = async (
  event,
  session,
  specialtyDiploma = false,
  fromHistory = false,
  series = false,
  ignoreInvoice = false
) => {
  console.log(
    "🛁 makeDiploma:",
    event,
    session,
    specialtyDiploma,
    fromHistory,
    series,
    ignoreInvoice
  );

  if (!fromHistory) {
    let canDownload = await validateDiploma(event, session);
    if (!canDownload) return;
  }

  let fullName = await getName();

  // try {
  let invoice = null;

  if (specialtyDiploma && !ignoreInvoice)
    invoice = await diplomaWithInvoice(event, session);

  let diplomaID = null;
  if (specialtyDiploma) diplomaID = specialtyDiploma;
  if (!specialtyDiploma) diplomaID = event?.diploma?.id;
  if (series?.diploma_id) diplomaID = series?.diploma_id;

  let myDiploma = await new api.GetDiploma(diplomaID).doQuery();
  console.log("my diploma -> ", myDiploma);
  console.log("fromHistory -> ", fromHistory);

  if (fromHistory && myDiploma.invoice_pos_x && myDiploma.invoice_pos_y)
    invoice = await diplomaWithInvoice(event, session);

  console.log("invoice->", invoice);
  if (invoice && "no_invoices" in invoice) return;

  const slug = event.slug;
  const url = myDiploma.url;
  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  const { width, height } = firstPage.getSize();
  const fontSize = myDiploma?.font_size ? Number(myDiploma?.font_size) : 28;
  const xPosition = Number(myDiploma.pos_x);
  const yPosition = height - Number(myDiploma.pos_y);

  firstPage.drawText(fullName, {
    x: xPosition,
    y: yPosition,
    size: fontSize,
    font: helveticaFont,
    color: myDiploma.color ? rgb(1, 1, 1) : rgb(0, 0, 0),
  });

  if (invoice) {
    firstPage.drawText(invoice.value, {
      x: Number(myDiploma.invoice_pos_x),
      y: Number(myDiploma.invoice_pos_y),
      //size: 20,
      size: myDiploma?.invoice_font_size
        ? Number(myDiploma.invoice_font_size)
        : 20,
      font: helveticaFont,
      color: myDiploma.color ? rgb(1, 1, 1) : rgb(0, 0, 0),
    });
  }

  const pdfBytes = await pdfDoc.save();
  fileDownload(pdfBytes, `evento-${slug}`, "application/pdf");

  let markDiplomaDownloaded = null;

  if (series?.diploma_id) {
    markDiplomaDownloaded = await new api.MarkSeriesDiplomaDownloaded(
      event.id,
      series?.diploma_id,
      series?.percentage
    ).doMutation(null, session.user.image.access_token);

    if (!markDiplomaDownloaded) {
      createDiplomaEvent6({
        eVar23: series?.diploma_id,
        eVar83: `Constancia | ${event.title}`,
      });
    }
  }

  if (!series?.diploma_id) {
    markDiplomaDownloaded = await new api.MarkDiplomaDownloaded(
      event.id,
      specialtyDiploma ? specialtyDiploma : event.diploma.id
    ).doMutation(null, session.user.image.access_token);

    if (!markDiplomaDownloaded) {
      createDiplomaEvent6({
        eVar23: specialtyDiploma ? specialtyDiploma : event.diploma.id,
        eVar83: `Constancia | ${event.title}`,
      });
    }
  }

  console.log("marked:", markDiplomaDownloaded);

  // } catch (error) {
  //   console.error('Diploma error ->', error)
  // }
};

export function useScrollDetector() {
  const [position, setPosition] = useState(0);
  const [prevPosition, setPrevPosition] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [scrollingElement, setScrollingElement] = useState(null);
  const [direction, setDirection] = useState("stopped");
  const [scrollSpeed, setScrollSpeed] = useState(0);

  function calculateAndSetDirection(oldPosition, newPosition) {
    if (newPosition > oldPosition) {
      setDirection("down");
    } else if (newPosition < oldPosition) {
      setDirection("up");
    } else {
      setDirection("stopped");
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      setScrollingElement(window);
    }
  }, []);

  useEffect(() => {
    let scrollTimeout = null;
    const checkScrollSpeed = (function (settings) {
      settings = settings || {};

      var lastPos,
        newPos,
        timer,
        delta,
        delay = settings.delay || 100; // in "ms" (higher means lower fidelity )

      function clear() {
        lastPos = null;
        delta = 0;
      }

      clear();

      return function () {
        newPos = scrollingElement.scrollY;
        if (lastPos != null) {
          // && newPos < maxScroll
          delta = newPos - lastPos;
        }
        lastPos = newPos;
        clearTimeout(timer);
        timer = setTimeout(clear, delay);
        return delta;
      };
    })();
    if (scrollingElement) {
      scrollingElement.addEventListener("scroll", (e) => {
        clearTimeout(scrollTimeout);
        setIsScrolling(true);
        setPrevPosition(scrollingElement.pageYOffset);
        setScrollSpeed(Math.abs(checkScrollSpeed()));
        scrollTimeout = setTimeout(function () {
          setIsScrolling(false);
          setDirection("stopped");
          setScrollSpeed(0);
        }, 66);
      });
    }

    return () => {
      if (scrollingElement) {
        scrollingElement.removeEventListener("scroll", (e) => {
          setIsScrolling(false);
          setPosition(0);
          setPrevPosition(0);
          setDirection("stopped");
        });
      }
    };
  }, [scrollingElement]);

  useEffect(() => {
    if (scrollingElement && position !== scrollingElement.pageYOffset) {
      calculateAndSetDirection(position, scrollingElement.pageYOffset);
      setPosition(scrollingElement.pageYOffset);
    }
  }, [isScrolling, prevPosition, position, scrollingElement]);

  return [isScrolling, direction, scrollSpeed, position];
}

/**miniplayer */
export const calculatePlayerSizes = (setPlayerSizes) => {
  let width = window.innerWidth;

  if (width > 0 && width <= 640)
    //console.log('xs')
    setPlayerSizes({ x: 0, y: 120, scale: 0.9, position: 100 });

  if (width > 640 && width <= 768)
    //console.log('sm')
    setPlayerSizes({ x: 0, y: 110, scale: 0.8, position: 50 });

  if (width > 768 && width <= 1024)
    //console.log('md')
    setPlayerSizes({ x: 15, y: 100, scale: 0.7, position: 10 });

  if (width > 1024 && width <= 1280)
    //console.log('lg')
    setPlayerSizes({ x: 45, y: 100, scale: 0.6, position: 40 });

  if (width > 1280)
    //console.log( 'xl' )
    setPlayerSizes({ x: 43, y: 100, scale: 0.65, position: 70 });
};

export const loadLivePlayer = (
  art,
  firstPlay,
  setIsPlaying,
  event,
  session,
  specialtyDiploma,
  eventStatus,
  videoBreakout,
  setAutoPlay,
  setHasPlayed
) => {
  let campaignNookies = getCampaignNookies();

  art.on("play", async () => {
    setHasPlayed(true);
    setIsPlaying(true);
    setAutoPlay(true);

    if (firstPlay) {
      firstPlay = false;

      let currentTalk = null;

      if (eventStatus === "breakout")
        currentTalk = await new api.GetCurrentTalkBreakout(
          event.id,
          event.day,
          videoBreakout.id
        ).doMutation(null, session.user.image.access_token);

      if (eventStatus === "live" || eventStatus === "dryrun")
        currentTalk = await new api.GetCurrentTalk(
          event.id,
          event.day
        ).doMutation(null, session.user.image.access_token);

      let createView = await new api.CreateView(
        "live",
        "video",
        "online",
        "web",
        currentTalk.duration,
        currentTalk.id,
        event.id,
        1,
        campaignNookies?.utm_source ?? "direct",
        campaignNookies?.utm_medium ?? "none",
        campaignNookies?.utm_campaign ?? "none"
      ).doMutation(null, session.user.image.access_token);

      if (event?.diploma?.id) {
        let diploma = await new api.CreateDiplomaReport(
          event.id,
          specialtyDiploma ? specialtyDiploma : event.diploma.id
        ).doMutation(null, session.user.image.access_token);
        console.log("createView, diploma->", createView, diploma);
      }
    }
  });

  art.on("pause", () => {
    setIsPlaying(false);
  });
};

export const loadVodPlayer = (
  art,
  firstPlay,
  setIsPlaying,
  event,
  video,
  session,
  specialtyDiploma,
  setAutoPlay
) => {
  art.on("play", async () => {
    setIsPlaying(true);
    setAutoPlay(true);

    if (!firstPlay) setIsPlaying(true);

    if (firstPlay) {
      firstPlay = false;
      let createView = await new api.CreateView(
        "vod",
        "video",
        "online",
        "web",
        video.duration,
        video.id,
        event.id,
        1,
        "direct",
        "none",
        "none"
      ).doMutation(null, session.user.image.access_token);

      if (event?.diploma?.id) {
        let diploma = await new api.CreateDiplomaReport(
          event.id,
          specialtyDiploma ? specialtyDiploma : event.diploma.id
        ).doMutation(null, session.user.image.access_token);
        console.log("createView, diploma->", createView, diploma);
      }

      setIsPlaying(true);
    }
  });

  art.on("pause", () => {
    setIsPlaying(false);
  });
};

export const artPlayerDefaults = {
  volume: 100,
  muted: false,
  autoplay: false,
  autoSize: true,
  screenshot: false,
  setting: false,
  //loop: true,
  playbackRate: true,
  aspectRatio: true,
  fullscreen: true,
  fullscreenWeb: false,
  //mutex: true,
  // theme: "#0089d9",
  theme: "#00c064",
  lang: "en",
  whitelist: ["*"],

  moreVideoAttr: {
    crossOrigin: "anonymous",
    playsInline: true,
    "webkit-playsinline": true,
  },
};

export const getParams = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString.toString());

  const status = urlParams.get("status");
  const url = urlParams.get("url");
  const url2 = urlParams.get("url2");
  const hasDiploma = urlParams.get("hasDiploma");

  return { status: status, url: url, url2: url2, hasDiploma: hasDiploma };
};

export const getDiplomaParam = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString.toString());

  const hasDiploma = urlParams.get("hasDiploma");

  return { hasDiploma: hasDiploma };
};

export const getSourceParams = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString.toString());

  const source = urlParams.get("utm_source");
  const medium = urlParams.get("utm_medium");
  //const url2 = urlParams.get("url2")

  return { source: source, medium: medium };
};

export const getCampaignNookies = () => {
  let campaignCookies = null;

  const cookies = parseCookies();

  if (cookies?.campaign) campaignCookies = JSON.parse(cookies?.campaign);

  return campaignCookies;
};

export const itgSetup = (event, art) => {
  const ITGIframe = document.createElement("iframe");
  ITGIframe.src = event.itg_url;
  ITGIframe.id = "itg-extension-iframe";
  ITGIframe.style.position = "absolute";
  ITGIframe.style.height = "calc(100% - 50px)";
  ITGIframe.style.width = "100%";
  ITGIframe.style.border = "none";
  ITGIframe.style.top = "0";
  ITGIframe.style.left = "0";
  ITGIframe.style.zIndex = "999999";

  let artVideoPlayer = document.getElementsByClassName("art-video-player");
  artVideoPlayer[0].appendChild(ITGIframe);

  window.addEventListener("message", function (e) {
    if (e.data.action === "itg_ready") {
      artVideoPlayer[0].classList.add("ready");
    }
    if (e.data.action === "itg_click") {
      console.log("ITG Click");
      art.player.toggle = true;
    }
  });
};

export const fetchTypeformResponse = async (event, e, session) => {
  console.log("called fetchScore -> ");

  //let scoreResponse = await axios.get('/typeform/' + e.response_id)
  let typeformResponse = await new api.GetTypeformResponse(
    event.exam?.exam_typeform_id,
    e.response_id
  ).doMutation(null, session.user.image.access_token);

  console.log("typeFormResponse -> ", typeformResponse);
  let response = JSON.parse(JSON.parse(typeformResponse));

  console.log("reponse -> ", response);

  return response;
};

export const examButton = (event, session, live = true) => {
  const [openedExam, setOpenedExam] = useState(false);
  const [examTries, setExamTries] = useState(0);

  const getExamTries = async () => {
    let examTries = await new api.GetExamTries(event.id).doQuery(
      null,
      session.user.image.access_token
    );
    setExamTries(examTries?.exam_tries);
  };

  const openExamTypeFormModal = (event, session) => {
    if (examTries >= 3) {
      Swal.fire({
        title: "Máximo número de intentos alcanzado",
        icon: "error",
        confirmButtonText: "Entendido",
      });
      return;
    }

    setOpenedExam(true);
    console.log(
      "typeform->",
      `https://form.typeform.com/to/${event?.exam?.exam_typeform_id}#iu=${session.user.image.user_id}`
    );
    typeformEmbed
      .createPopup(
        `https://form.typeform.com/to/${event?.exam?.exam_typeform_id}#iu=${session.user.image.user_id}`,
        {
          hideHeaders: true,
          hideFooter: true,
          // onSubmit: async e => {
          //   console.log("responsive  -> ", e.response_id);

          //   let score = null;
          //   while (true) {
          //     score = await fetchTypeformResponse(event, e, session);
          //     console.log("response inside while -> ", score, score?.items[0]?.calculated?.score);
          //     if (score?.items.length) break;
          //   }
          //   score = score?.items[0]?.calculated?.score;

          //   //save score in general_perecentages
          //   let saveExamScore = await new api.SaveExamScore(event.id, score).doMutation(null, session.user.image.access_token);
          //   console.log("exam score saved -> ", saveExamScore);

          //   let examTries = await new api.GetExamTries(event.id).doQuery(null, session.user.image.access_token);
          //   setExamTries(examTries?.exam_tries);
          //   //setExamTries( examTries + 1 )

          //   setOpenedExam(false);
          // },
          onClose: () => {
            setOpenedExam(false);
          },
        }
      )
      .open();
  };

  useEffect(() => {
    getExamTries();
  }, []);

  return (
    <button
      className={
        live
          ? "flex flex-col justify-center items-center p-3 lg:p-1 lg:m-3 lg:border-b-2 border-gray-100 cursor-pointer w-1/4 lg:w-auto"
          : "ml-3 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      }
      onClick={() => openExamTypeFormModal(event, session)}
    >
      <Exam
        data-name="exam"
        width={live ? "40px" : "20px"}
        height={live ? "40px" : "20px"}
        className={`fill-current ${live ? "text-gray-800" : "hidden"} ${
          openedExam && "text-bi-green-malachite-500"
        }`}
      />

      <span
        data-name="exam"
        className={` ${
          live
            ? "text-xs my-3 lg:my-0 text-gray-800"
            : "font-sm leading-5 text-gray-600  font-medium"
        } ${openedExam && "text-bi-green-malachite-500"}`}
      >
        Evaluación
      </span>
    </button>
  );
};

export const formatBarData = (array) => {
  let barData = array.map((row) => {
    return { id: row.value, países: row.value, value: row.total };
  });

  barData = barData.filter(({ id }) => id !== "NO-APLICA");

  barData.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));

  return barData;
};

export const formatData = (array) => {
  return array.map((row) => {
    return { x: row.at_minute, y: row.count };
  });
};

export const GetRealTime = async (
  day,
  event,
  session,
  setRealtime,
  setCurrentDay
) => {
  const realTimes = await getRealTime(day, event.id);
  setRealtime(formatData(realTimes));
  setCurrentDay(day);
};

export const formatSpecialtyPieData = (array) => {
  let total = 0;
  let percentage = 0;

  array.map((row) => {
    total += Number(row.total);
  });

  let fourSpecialties = array.filter((row, i) => {
    percentage = (row.total / total) * 100;
    if (i < 4)
      return { id: row.value, label: row.value, value: percentage.toFixed(2) };
  });

  let restSpecialties = array.reduce((accumulator, _, i, sourceArray) => {
    if (i < 4) return accumulator + 0;
    if (i >= 4) return accumulator + Number(sourceArray[i].total);
  }, 0);
  restSpecialties = [
    { __typename: "Statistic", value: "OTROS", total: `${restSpecialties}` },
  ];

  let merged = fourSpecialties.concat(restSpecialties);

  return merged.map((row) => {
    percentage = (row.total / total) * 100;
    return { id: row.value, label: row.value, value: percentage.toFixed(2) };
  });
};

export const formatPieData = (array) => {
  let total = 0;
  let percentage = 0;
  array.map((row) => {
    total += Number(row.total);
  });

  return array.map((row) => {
    percentage = (row.total / total) * 100;
    return { id: row.value, label: row.value, value: percentage.toFixed(2) };
  });
};

export const myAgentGraphs = [
  {
    title: "Dispositivos",
    value: "device",
    formatData: formatPieData,
    data: [],
  },
  {
    title: "Navegadores",
    value: "browser",
    formatData: formatPieData,
    data: [],
  },
  {
    title: "Sistemas operativos",
    value: "OS",
    formatData: formatPieData,
    data: [],
  },
  {
    title: "Especialidades",
    value: "specialties.name",
    formatData: formatSpecialtyPieData,
    data: [],
  },
];

export const myUserGraphs = [
  {
    title: "Países",
    value: "country_id",
    formatData: formatBarData,
    data: [],
    field: "countries",
  },
  {
    title: "Estados",
    value: "state_id",
    formatData: formatBarData,
    data: [],
    field: "states",
  },
];

export const getCourseInfo = async (
  session,
  setCourse,
  setTotalCourses,
  setPercentage,
  slug
) => {
  const courseInfo = await new api.GetCourseInfo(slug).doQuery(
    null,
    session.user.image.access_token
  );
  setCourse(courseInfo);

  const totalCourses = await new api.GetTotalCourseVideos(slug).doQuery(
    null,
    session.user.image.access_token
  );
  setTotalCourses(totalCourses);

  const courseProgress = await new api.GetCourseProgress(slug).doQuery(
    null,
    session.user.image.access_token
  );
  setPercentage(courseProgress);
};

export const requestSchema = yup.object().shape({
  // name: yup.string().max(40, 'Ingrese un nombre válido').required('El nombre es obligatorio.'),
  // lastName: yup.string().max(40, 'Ingrese un apellido paterno válido').required('El apellido paterno es obligatorio.'),
  // secondLastName: yup.string().max(40, 'Ingrese un apellido materno válido').required('El apellido materno es obligatorio.'),
  // specialtyId: yup.number().typeError('La especialidad es obligatoria').required('La especialidad es obligatoria.'),
  // license: yup.string().required('La cédula profesional es obligatoria.'),
  description: yup.string().required("La descripción es obligatoria."),
});

export const truncateWords = (sentence, amount = 12, tail = "...") => {
  if (!sentence) {
    return "";
  }
  const words = sentence.split(" ");

  if (amount >= words.length) return sentence;

  const truncated = words.slice(0, amount);
  return `${truncated.join(" ")}${tail}`;
};

export async function filter(arr, callback) {
  const fail = Symbol();
  return (
    await Promise.all(
      arr.map(async (item) => ((await callback(item)) ? item : fail))
    )
  ).filter((i) => i !== fail);
}

export const filterInWhitelist = async (arr, context) => {
  const result = await filter(arr, async ({ id2: eventID }) => {
    if (eventID == "-1") return true;
    let isEventInWhiteList = await new api.IsEventInWhiteList(eventID).doQuery(
      context
    );
    return isEventInWhiteList;
  });
  return result;
};

export const tryCatch = async (promise, context, ...args) => {
  try {
    const data = await new promise(...args).doQuery(context);
    return [data, null];
  } catch (e) {
    return [null, e];
  }
};

export const shimmer = (
  w,
  h
) => `<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
		<defs>
		<linearGradient id="g">
			<stop stop-color="#f3f3f3" offset="20%" />
			<stop stop-color="#ecebeb" offset="50%" />
			<stop stop-color="#f3f3f3" offset="70%" />
		</linearGradient>
		</defs>
		<rect width="${w}" height="${h}" fill="#f3f3f3" />
		<rect id="r" width="${w}" height="${h}" fill="url(#g)" />
		<animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
	</svg>`;

export const toBase64 = (str) => {
  return typeof window === "undefined"
    ? Buffer.from(str).toString("base64")
    : window.btoa(str);
};

export const capitalizeFirstLetter = (str) => {
  return `${str[0].toUpperCase()}${str.slice(1)}`;
};

export const answerVideoExam = (exam_typeform_id, session, videoID) => {
  console.log(
    "typeform->",
    `https://form.typeform.com/to/${exam_typeform_id}#iu=${session.user.image.user_id}`
  );
  typeformEmbed
    .createPopup(
      `https://form.typeform.com/to/${exam_typeform_id}#iu=${session.user.image.user_id}`,
      {
        hideHeaders: true,
        hideFooter: true,
        // onSubmit: async e => {
        //   let score = null;

        //   while (true) {
        //     score = await fetchTypeformResponse({ exam: { exam_typeform_id: exam_typeform_id } }, e, session);
        //     console.log("response inside while -> ", score, score?.items[0]?.calculated?.score);
        //     if (score?.items.length) break;
        //   }
        //   score = score?.items[0]?.calculated?.score;

        //   const saveVideoScore = await new api.SaveVideoScore(videoID, score, e.response_id).doMutation(
        //     null,
        //     session.user.image.access_token
        //   );
        //   console.log("save video score ->", saveVideoScore);
        // },
      }
    )
    .open();
};
export const isCrawlerUser = (userAgent) => {
  let isCrawler = false;
  let botPattern =
    "(googlebot/|bot|Googlebot-Mobile|LinkedInBot|WhatsApp|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";
  let re = new RegExp(botPattern, "i");
  isCrawler = re.test(userAgent) ? true : false;
  // return true
  return isCrawler;
};

// const bannedDashboardEmails = [
//   "Fzaventas@boehringer-ingelheim.com",
//   "fzaventas@boehringer-ingelheim.com",
//   "fzadeventas@boehringer-ingelheim.com",
//   "fezaventas@boehringer-ingelheim.com",
// ];

export const dashboardLoginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Ingresa un correo electrónico válido")
    .required("El correo electrónico es obligatorio")
    .test(
      "emails",
      "Por favor asegúrate de entrar con tu correo de Boehringer corporativo",
      (value) => !blackListedEmails.includes(value)
    ),
});

export const createDashboardAccess = (email) => {
  const cookies = parseCookies();

  if (cookies?.dashboard) return;

  let stringifyParams = JSON.stringify({
    last_access: new Date(),
    email: email,
  });

  nookies.set(null, "dashboard_access", stringifyParams, {
    maxAge: 60 * 60,
    path: "/",
  });
};

export const getDashboardAccess = (ctx) => {
  const cookies = nookies.get(ctx);
  return cookies?.dashboard_access ? true : false;
};
